import React from 'react'
import { graphql } from "gatsby"
import Layout from "./layout"
import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text } from '@rebass/emotion'
import { css } from '@emotion/react'
import SubPage from './subPage'
import orderBy from 'lodash/orderBy'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const c = css`
    font-weight: 400;
    line-height: 2em;
    hyphens: auto;
    width: 90%;
    max-width: 70rem;
    margin: 2.5rem auto 0 auto;

    em {
        font-style: normal;
        font-weight: 400;
        hyphens: manual;
        color: #4975ba;
        border-bottom: 1px solid #4975ba;
    }
`




const Single = ({ data: { project: node } }) => {
    return (
        <Layout title={`Projekt ${node.projekt} – ${node.titelLagebeschreibung}`}>
            <SubPage>
                <Box mb={[50, 50, 50, 100]}>
                    <Flex justifyContent="center">
                        <Box width={[1, 1 / 1.5]}>
                            <Text color="#6f7c82" fontSize={[18, 18, 18, 20]} fontWeight={600} textAlign="center">Referenzen</Text>
                            <Heading color="#292e31" fontSize={[30, 42, 46, 60]} fontWeight={600} textAlign="center">{node.titelLagebeschreibung}</Heading>
                            <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} textAlign="center">
                                {node.beschreibung}<br />
                            </Text>
                            <Text color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} textAlign="center">
                                {node.projekt}
                            </Text>

                            <Box mt={20}>
                                {['konstruktionsVariante', 'gelander', 'beschichtung', 'entwasserungebene', 'fussboden']
                                    .filter(key => node[key])
                                    .map((key) =>
                                        <Text key={key} color="#32335c" fontWeight={400} lineHeight="1.6em" fontSize={[14, 14, 16, 18]} textAlign="center">
                                            {node[key]}
                                        </Text>
                                    )}
                            </Box>

                        </Box>
                    </Flex>
                </Box>
                <Box py={[20, 50]} css={{
                    backgroundColor: '#F3F5F6'
                }}>
                    <Flex mx={"auto"} css={{ maxWidth: 1250 }} width={[0.9, 0.9, 0.9, 1]} flexDirection="column" m={0}>
                        <Flex justifyContent="flexStart" flexWrap="wrap">
                            {orderBy(node.images, ['name']).map((image, idx) =>
                                <Box width={[1, 1, 1 / 2]} px={[0, 20]} py={[0, 20]} my={[10, 20]} key={idx}>
                                    <GatsbyImage image={getImage(image.file)} alt={node.projekt + " " + node.beschreibung} />
                                    <Text fontSize={14} my={10}>{image.name}</Text>
                                </Box>
                            )}
                        </Flex>
                    </Flex>
                </Box>
            </SubPage>
        </ Layout >
    )
}

export default Single

export const query = graphql`
    query ($slug: String) {
        project(slug: {eq: $slug}) {
            id
            titelLagebeschreibung
            beschreibung
            projekt
            konstruktionsVariante
            gelander
            beschichtung
            entwasserungebene
            fussboden
            images {
                projekt
                name
                file {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
            }
        }
    }

`